import { useLanguage } from './useLanguage';
import { computed } from 'vue';

import { en } from '../i18n/en';
import { de } from '../i18n/de';
import { es } from '../i18n/es';

export const availableTranslations = {
  en,
  de,
  es,
};

export type Languages = keyof typeof availableTranslations;

export function useTranslations() {
  const { currentLanguage } = useLanguage();

  const translations = computed(
    () => availableTranslations[currentLanguage.value],
  );

  return {
    translations,
  };
}
