import { createApp } from 'vue';
import './style.css';
import App from './App.vue';
import { router } from './router';
import * as Sentry from '@sentry/vue';

const app = createApp(App);

app.use(router);
app.mount('#app');

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: 'https://b9a8b03a68b5c133590180f6239553bf@o4507456830504960.ingest.de.sentry.io/4507456836272208',
    integrations: [Sentry.browserTracingIntegration()],
    environment: import.meta.env.MODE,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  import('@sentry/vue')
    .then((lazyLoadedSentry) => {
      Sentry.addIntegration(
        lazyLoadedSentry.replayIntegration({
          maskAllText: false,
          maskAllInputs: false,
          blockAllMedia: false,
        }),
      );
      Sentry.addIntegration(lazyLoadedSentry.replayCanvasIntegration());
      Sentry.addIntegration(
        lazyLoadedSentry.feedbackIntegration({
          colorScheme: 'system',
          showBranding: false,
          triggerLabel: 'Give Feedback',
          formTitle: 'Give Feedback',
          submitButtonLabel: 'Send Feedback',
          messagePlaceholder: 'What feedback do you have?',
          successMessageText: 'Thank you for your feedback!',
        }),
      );
    })
    .catch((error) => {
      console.error('Error loading Sentry integrations', error);
    });
}
