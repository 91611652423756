<script setup lang="ts">
// import { usePeer } from '../src//composables//usePeerGlobally.ts';
// import { onMounted } from 'vue';
// import { useState } from './composables/useState.ts';
// import { translations } from './i18n/translations.ts';
// import { useLanguage } from './composables/useLanguage.ts';
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import { useLanguage } from './composables/useLanguage';
import { onMounted } from 'vue';
import {
  availableTranslations,
  Languages,
} from './composables/useTranslations';

const { setLanguage } = useLanguage();

// import type { Languages } from './i18n/translations.ts';

// const { setUsername, debugMode } = useState();
// const { peerId, fetchPeer, fetchingPeer, fetchingError, cleanUpPeer } =
//   usePeer(onPeerFetched);
// const { currentLanguage, setLanguage, availableLanguages } = useLanguage();

onMounted(() => {
  if (localStorage.getItem('language')) {
    const language = localStorage.getItem('language');
    if (language && language in availableTranslations)
      setLanguage(language as Languages);
  }
});

// function onBeforeUnloadApp() {
//   console.log('on beforeunload app');
//   cleanUpPeer();
// }

// function onPeerFetched() {
//   console.log('peerId fetched:', peerId.value);
// }
</script>

<template>
  <div class="">
    <Header />

    <!-- <section v-if="debugMode">
      <div>{{ peerId ?? 'No PeerId' }}</div>
      <span v-if="fetchingPeer">{{
        t(translations.texts.loading)
      }}</span>
      <p v-if="fetchingError">{{ fetchingError }}</p>
    </section> -->

    <main>
      <RouterView />
    </main>

    <Footer />
  </div>
</template>

<style>
/* Minimum size to prevent zoom on iOS when focusing on input */
input,
textarea,
select {
  font-size: 16px;
}
body {
  /* previewjs reset */
  padding: 0;
}
</style>
