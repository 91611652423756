<script setup lang="ts">
import { useTranslations } from '../composables/useTranslations';

const { translations } = useTranslations();
</script>

<template>
  <footer class="bg-gray-800 text-white py-12 px-6">
    <div
      class="container mx-auto max-w-5xl flex flex-col md:flex-row justify-between items-center"
    >
      <div class="flex items-center">
        <div
          class="bg-gradient-to-r from-primary to-secondary p-1.5 rounded-lg mr-2"
        >
          <div class="size-6 rounded-md bg-gray-200"></div>
        </div>

        <span class="text-sm"
          >&copy; {{ new Date().getFullYear() }} Language Rooms.
          {{ translations.texts.allRightsReservedFunny }}</span
        >
      </div>
    </div>
  </footer>
</template>
