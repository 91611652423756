// Rules:
// 1. Use sentence case and only title case for words that need it
// 2. Use the informal "you" form for all languages
// 3. Make sure the text is concise and easy to understand and grammatically correct

export const en = {
  general: {
    nativeName: 'English',
    language: 'Language',
  },
  greetings: {
    hello: 'Hello',
    welcome: 'Welcome',
    enterName: 'Enter your name',
  },
  difficulties: {
    Beginner: 'Beginner',
    Intermediate: 'Intermediate',
    Advanced: 'Advanced',
    whoIsThisLevelFor: 'Who is this level for?',
    descriptions: {
      Beginner:
        "Beginner rooms are ideal if you're new to the language. They're perfect for practicing basic phrases and simple conversations. You'll build confidence in speaking and understanding fundamental concepts, focusing on essential grammar and everyday vocabulary.",
      Intermediate:
        "If you can hold simple conversations and want to improve your fluency, intermediate rooms are for you. You'll engage in more complex discussions, explore language nuances, and start understanding idiomatic expressions. Work on refining your grammar and expressing more complex ideas.",
      Advanced:
        "Advanced rooms are for highly proficient learners aiming for near-native fluency. If you can discuss complex topics confidently, these rooms are right for you. You'll engage in sophisticated discussions on literature, culture, and current events, working with advanced vocabulary and subtle language nuances.",
    },
  },
  headlines: {
    rooms: 'Rooms',
    whyItWorks: 'Why it works',
    howItWorks: 'How it works',
    targetGroup: 'Target Group',
    earlyAccess: 'Early Access',
    speakLearnConnect: 'Speak. Learn. Connect.',
    whyLanguageRoomsWorks: 'Why Language Rooms works',
    theLanguageRoomsMethod: 'The Language Rooms Method',
    howLanguageRoomsWorks: 'How Language Rooms works',
    becomeALanguageRoomsPioneer: 'Become a Language Rooms pioneer',
    yourVoiceMatters: 'Your voice matters',
    perfectForIntermediateLearnersAndBeyond:
      'Perfect for Intermediate Learners and Beyond',
    whoBenefitsMost: 'Who benefits most',
    ourExcitingRoadmap: 'Our exciting roadmap',
    whyYourFeedBackIsCrucial: 'Why your feedback is crucial',
    howToProvideFeedback: 'How to provide feedback',
    speakEarlySpeakOften: 'Speak early, Speak often',
    learnFromNativeSpeakers: 'Learn from native speakers',
    embraceTheFun: 'Embrace the fun',
    progressingLearners: 'Progressing Learners',
    rustySpeakers: 'Rusty Speakers',
    skillMaintainers: 'Skill Maintainers',
    culturalEnthusiasts: 'Cultural Enthusiasts',
    joinARoom: 'Join a room',
    startSpeaking: 'Start speaking',
    levelUpYourLingo: 'Level up your lingo',
    phaseOneLaunch: 'Phase 1: Launch',
    phaseTwoCommunityGrowth: 'Phase 2: Community Growth',
    phaseThreeGlobalScale: 'Phase 3: Global Scale',
    traditionalMethod: 'Traditional Method',
    languageRoomsMethod: 'Language Rooms Method',
  },
  buttons: {
    join: 'Join',
    save: 'Save',
    cancel: 'Cancel',
    leave: 'Leave',
    checkAudioDevices: 'Check audio devices',
    mute: 'Mute',
    unmute: 'Unmute',
    back: 'Back',
    joinTheWaitlist: 'Join the waitlist',
    exploreRooms: 'Explore rooms',
    requestEarlyAccess: 'Request early access',
    earlyAccessRequested: 'Early access requested',
  },
  texts: {
    allRightsReservedFunny:
      "All rights reserved. Now go practice your rolled R's!",
    connectWithNativeSpeakersEngage:
      'Connect with native speakers, engage in real conversations and learn through speaking.',
    ourPlatformFocusesOnSpeaking:
      'Our platform focuses on speaking first, making language learning fun and effective.',
    thisExperienceWasntJustAPersonalVictory:
      'We have three core principles as the foundation of Language Rooms. They are tested and proven to provide rapid and effective learning.',
    weHaveThreeCorePrinciples:
      'We have three core principles as the foundation of Language Rooms. They are tested and proven to provide rapid and effective learning.',
    languageRoomsIsDesignedTo:
      "Language Rooms is designed to take your skills to the next level. Whether you're looking to improve, maintain, or regain your language abilities, our platform provides the perfect environment for your journey.",
    wereGraduatelyOpeningOurDoors:
      "We're gradually opening our doors. Join our waitlist for early access and be among the first to experience the future of language learning!",
    weValueYourInput:
      'We value your input! Help shape the future of Language Rooms by sharing your thoughts and experiences.',
    creator: 'Creator',
    participants: 'Participants',
    roomName: 'Room name',
    roomDescription: 'Room description',
    roomCreatedBy: 'Room created by',
    roomCreatedAt: 'Room created at',
    createRoom: 'Create room',
    joinRoom: 'Join room',
    joinLanguageRoom: 'Join language room',
    languageRoom: 'Language room',
    room: 'Room',
    roomSettings: 'Room settings',
    roomMembers: 'Room members',
    roomMessages: 'Room messages',
    loading: 'Loading...',
    nobodyHere: 'Nobody here',
    you: 'You',
    selectYourDesiredAudioDevices: 'Select your desired audio devices',
    makeSureYouHaveTheCorrectAudioDevicesSelected:
      'Make sure you have the correct audio devices selected',
    makeSureYouHaveAllowedMicrophoneAccess:
      'Make sure you have allowed the browser to access your microphone',
    microphone: 'Microphone',
    speakers: 'Speakers',
    difficulty: 'Difficulty',
    audioSettings: 'Audio settings',
    enterYourEmail: 'Enter your email',
    weAreExcitedToWelcomeYouSoon: 'We are excited to welcome you soon!',
    onceYourEmailIsApproved:
      "Once your email is approved, you'll receive full access to Language Rooms and can start your speaking journey immediately!",
    lookForTheFeedbackButton:
      'Look for the "Give Feedback" button at the bottom right of every page. Your insights help us create the best possible language learning experience.',
    sevenYears: '7 Years',
    threeMonths: '3 Months',
    yearsOfTextBooksAndGrammar:
      'Years of textbooks and grammar drills led to perfect conjugations but panic in real conversations.',
    immersiveLearningAndRealConversations:
      'Immersive learning and real conversations led to confident Spanish skills in just three months.',
    noMoreYearsOfSilentStudy:
      'No more years of silent study. Start speaking immediately, even if it\'s just "Hola" on repeat. It\'s how I jumpstarted my Spanish journey.',
    textBooksCantTeachYou:
      "Textbooks can't teach you how locals really talk. Our platform connects you with native speakers for authentic, practical language use.",
    learningALanguageShouldBeFun:
      "Learning a language should be enjoyable, not a chore. We've infused fun into every aspect of Language Rooms, because we know firsthand that it works.",
    alreadySpeakingALittle:
      'Already speak a little? Accelerate your progress through regular conversation practice with native speakers and fellow learners.',
    outOfPracticeQuestion:
      'Out of practice? Quickly regain your confidence and fluency in a supportive environment with patient conversation partners.',
    dontWantToLoseYourSkills:
      "Don't want to lose your skills? Maintain and improve your language abilities effortlessly through regular, engaging conversations on topics you love.",
    diveDeeperIntoTheCultures:
      "Dive deeper into the cultures behind the languages you're learning. Connect with native speakers and gain authentic insights.",
    pickAThemedRoom:
      'Pick a themed room that matches your interests. "Ordering Coffee Without Panic Attacks" is a popular choice.',
    startSpeakingWithOthers:
      "Start speaking with other learners and native speakers who won't judge your accent (much).",
    watchYourLanguageSkillsFlourish:
      "Watch your language skills flourish. Soon you'll be dreaming in your target language!",
    coreFeaturesAndInitialFeatures:
      'Core features and initial language rooms available to early access users.',
    expandUserbase:
      'Expand user base, introduce more languages, and enhance community features.',
    worldwideAccessibility:
      'Worldwide accessibility, cultural exchange programs, and advanced features.',
    shapeTheFutureOfLanguageRooms: 'Shape the future of Language Rooms',
    suggestNewFeaturesAndImprovements: 'Suggest new features or improvements',
    reportAnyIssues: 'Report any issues you encounter',
    shareYourSuccessStories: 'Share your success stories',
    extensiveGrammarFocus: 'Extensive grammar focus',
    vocabularyMemorization: 'Vocabulary memorization',
    limitedPracticalApplication: 'Limited practical application',
    focusOnPracticalUsage: 'Focus on practical usage',
    dailyConversationPractice: 'Daily conversation practice',
    highConfidenceInRealSituations: 'High confidence in real situations',
    siteLanguage: 'Site language',
    joinUsAndSpeakYourWay:
      'Join us and speak your way to fluency with Language Rooms!',
  },
  errors: {
    error: 'Error',
    localStreamNotFound:
      'Local stream not found. Make sure that you allow the browser to access your camera and microphone.',
  },
} as const;
