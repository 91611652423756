export const es = {
  general: {
    nativeName: 'Español',
    language: 'Idioma',
  },
  greetings: {
    hello: 'Hola',
    welcome: 'Bienvenido',
    enterName: 'Ingresa tu nombre',
  },
  difficulties: {
    Beginner: 'Principiante',
    Intermediate: 'Intermedio',
    Advanced: 'Avanzado',
    whoIsThisLevelFor: '¿Para quién es este nivel?',
    descriptions: {
      Beginner:
        'Las salas para principiantes son ideales si eres nuevo en el idioma. Son perfectas para practicar frases básicas y conversaciones simples. Ganarás confianza al hablar y entender conceptos fundamentales, centrándote en gramática esencial y vocabulario cotidiano.',
      Intermediate:
        'Si puedes mantener conversaciones simples y quieres mejorar tu fluidez, las salas para intermedios son perfectas para ti. Participarás en discusiones más complejas, explorarás matices del idioma y comenzarás a entender expresiones idiomáticas. Trabaja en refinar tu gramática y expresar ideas más complejas.',
      Advanced:
        'Las salas avanzadas están diseñadas para estudiantes altamente competentes que buscan alcanzar una fluidez casi nativa. Si puedes discutir temas complejos con confianza, estas salas son ideales para ti. Participarás en discusiones sofisticadas sobre literatura, cultura y eventos actuales, trabajando con vocabulario avanzado y sutilezas lingüísticas.',
    },
  },
  headlines: {
    rooms: 'Salas',
    whyItWorks: 'Por qué funciona',
    howItWorks: 'Cómo funciona',
    targetGroup: 'Grupo objetivo',
    earlyAccess: 'Acceso anticipado',
    speakLearnConnect: 'Habla. Aprende. Conecta.',
    whyLanguageRoomsWorks: 'Por qué Language Rooms funciona',
    theLanguageRoomsMethod: 'El método Language Rooms',
    howLanguageRoomsWorks: 'Cómo funciona Language Rooms',
    becomeALanguageRoomsPioneer: 'Conviértete en un pionero de Language Rooms',
    yourVoiceMatters: 'Tu voz importa',
    perfectForIntermediateLearnersAndBeyond:
      'Perfecto para estudiantes intermedios y más allá',
    whoBenefitsMost: 'Quién se beneficia más',
    ourExcitingRoadmap: 'Nuestra emocionante hoja de ruta',
    whyYourFeedBackIsCrucial: 'Tu retroalimentación es crucial',
    howToProvideFeedback: 'Cómo proporcionar retroalimentación',
    speakEarlySpeakOften: 'Habla temprano, habla a menudo',
    learnFromNativeSpeakers: 'Aprende de hablantes nativos',
    embraceTheFun: 'Hablar es divertido',
    progressingLearners: 'Estudiantes en progreso',
    rustySpeakers: 'Refrescar habilidades lingüísticas',
    skillMaintainers: 'Mantener habilidades lingüísticas',
    culturalEnthusiasts: 'Entusiastas culturales',
    joinARoom: 'Únete a una sala',
    startSpeaking: 'Empieza a hablar',
    levelUpYourLingo: 'Mejora tu lenguaje',
    phaseOneLaunch: 'Fase 1: Lanzamiento',
    phaseTwoCommunityGrowth: 'Fase 2: Crecimiento de la comunidad',
    phaseThreeGlobalScale: 'Fase 3: Escala global',
    traditionalMethod: 'Método tradicional',
    languageRoomsMethod: 'Método Language Rooms',
  },
  buttons: {
    join: 'Unirse',
    save: 'Guardar',
    cancel: 'Cancelar',
    leave: 'Salir',
    checkAudioDevices: 'Verificar dispositivos de audio',
    mute: 'Silenciar',
    unmute: 'Activar sonido',
    back: 'Atrás',
    joinTheWaitlist: 'Unirse a la lista de espera',
    exploreRooms: 'Explorar salas',
    requestEarlyAccess: 'Solicitar acceso anticipado',
    earlyAccessRequested: 'Acceso anticipado solicitado',
  },
  texts: {
    allRightsReservedFunny:
      '¡Todos los derechos reservados. Ahora ve y practica tus erres!',
    connectWithNativeSpeakersEngage:
      'Conéctese con hablantes nativos, participe en conversaciones reales y aprenda hablando.',
    ourPlatformFocusesOnSpeaking:
      'Nuestra plataforma se centra en hablar primero, haciendo que el aprendizaje de idiomas sea divertido y eficaz.',
    weHaveThreeCorePrinciples:
      'Tenemos tres principios fundamentales como base de Language Rooms. Están probados y comprobados para proporcionar un aprendizaje rápido y efectivo.',
    languageRoomsIsDesignedTo:
      'Language Rooms está diseñado para llevar tus habilidades al siguiente nivel. Ya sea que quieras mejorar, mantener o recuperar tus habilidades lingüísticas, nuestra plataforma ofrece el entorno perfecto.',
    wereGraduatelyOpeningOurDoors:
      '¡Estamos abriendo gradualmente nuestras puertas. Únete a nuestra lista de espera para acceso anticipado y sé uno de los primeros en experimentar el futuro del aprendizaje de idiomas!',
    weValueYourInput:
      '¡Valoramos tu opinión! Ayuda a dar forma al futuro de Language Rooms compartiendo tus ideas, deseos y experiencias.',
    creator: 'Creador',
    participants: 'Participantes',
    roomName: 'Nombre de la sala',
    roomDescription: 'Descripción de la sala',
    roomCreatedBy: 'Sala creada por',
    roomCreatedAt: 'Sala creada el',
    createRoom: 'Crear sala',
    joinRoom: 'Unirse a la sala',
    joinLanguageRoom: 'Unirse a la sala de idiomas',
    languageRoom: 'Sala de idiomas',
    room: 'Sala',
    roomSettings: 'Configuración de la sala',
    roomMembers: 'Miembros de la sala',
    roomMessages: 'Mensajes de la sala',
    loading: 'Cargando...',
    nobodyHere: 'Nadie aquí',
    you: 'Tú',
    selectYourDesiredAudioDevices:
      'Selecciona tus dispositivos de audio deseados',
    makeSureYouHaveTheCorrectAudioDevicesSelected:
      'Asegúrate de haber seleccionado los dispositivos de audio correctos',
    makeSureYouHaveAllowedMicrophoneAccess:
      'Asegúrate de haber permitido que el navegador acceda a tu micrófono',
    microphone: 'Micrófono',
    speakers: 'Altavoces',
    difficulty: 'Nivel de dificultad',
    audioSettings: 'Configuración de audio',
    enterYourEmail: 'Ingresa tu dirección de correo electrónico',
    weAreExcitedToWelcomeYouSoon:
      '¡Estamos emocionados de darte la bienvenida pronto!',
    onceYourEmailIsApproved:
      'Una vez que tu correo electrónico sea aprobado, ¡tendrás acceso completo a Language Rooms y podrás comenzar tu viaje lingüístico de inmediato!',
    lookForTheFeedbackButton:
      'Busca el botón "Dar retroalimentación" en la parte inferior derecha de cada página. Tu retroalimentación nos ayuda a crear la mejor experiencia de aprendizaje de idiomas posible.',
    sevenYears: '7 años',
    threeMonths: '3 meses',
    yearsOfTextBooksAndGrammar:
      'Años de aprendizaje con libros de texto y ejercicios de gramática llevaron a conjugar verbos perfectamente. Sin embargo, también llevaron al pánico en conversaciones reales.',
    immersiveLearningAndRealConversations:
      'Conversaciones reales llevaron a un dominio confiado del español en solo tres meses. Sin ataques de pánico al pedir café o en charlas casuales.',
    noMoreYearsOfSilentStudy:
      'Empieza a hablar lo antes posible, aunque solo sea "Hola". Así es como comenzó mi viaje con el español.',
    textBooksCantTeachYou:
      'Los libros de texto no pueden enseñarte cómo hablan realmente los nativos. Nuestra plataforma te conecta con hablantes nativos para un uso auténtico y práctico del idioma.',
    learningALanguageShouldBeFun:
      'Aprender un idioma debería ser divertido. Hemos incorporado diversión en cada aspecto de Language Rooms porque sabemos por experiencia propia que funciona.',
    alreadySpeakingALittle:
      '¿Ya hablas un poco? Acelera tu progreso a través de prácticas de conversación regulares con hablantes nativos y otros estudiantes.',
    outOfPracticeQuestion:
      '¿Fuera de práctica? Recupera rápidamente tu confianza y habilidades lingüísticas en un entorno de apoyo con compañeros de conversación pacientes.',
    dontWantToLoseYourSkills:
      '¿No quieres perder tus habilidades? Mantén y mejora tus conocimientos lingüísticos sin esfuerzo a través de conversaciones regulares y atractivas sobre temas que te apasionan.',
    diveDeeperIntoTheCultures:
      'Sumérgete más profundamente en las culturas detrás de los idiomas que estás aprendiendo. Conéctate con hablantes nativos y obtén perspectivas fascinantes sobre nuevas culturas.',
    pickAThemedRoom:
      'Elige una sala temática que se ajuste a tus intereses. "Pedir café sin ataques de pánico" es una opción popular.',
    startSpeakingWithOthers:
      'Empieza a hablar con otros estudiantes y hablantes nativos que no juzgarán (demasiado) tu acento.',
    watchYourLanguageSkillsFlourish:
      'Observa cómo tus habilidades lingüísticas mejoran constantemente. ¡Pronto estarás soñando en tu idioma objetivo!',
    coreFeaturesAndInitialFeatures:
      'Funciones principales y primeras salas de idiomas disponibles para usuarios con acceso anticipado.',
    expandUserbase:
      'Expansión de la base de usuarios, introducción de más idiomas y mejora de las funciones de la comunidad.',
    worldwideAccessibility:
      'Accesibilidad mundial, programas de intercambio cultural y funciones avanzadas.',
    shapeTheFutureOfLanguageRooms: 'Da forma al futuro de Language Rooms',
    suggestNewFeaturesAndImprovements: 'Sugiere nuevas funciones o mejoras',
    reportAnyIssues: 'Informa cualquier problema que encuentres',
    shareYourSuccessStories: 'Comparte tus historias de éxito',
    extensiveGrammarFocus: 'Enfoque extensivo en gramática',
    vocabularyMemorization: 'Memorización de vocabulario',
    limitedPracticalApplication: 'Aplicación práctica limitada',
    focusOnPracticalUsage: 'Enfoque en el uso práctico',
    dailyConversationPractice: 'Práctica de conversación diaria',
    highConfidenceInRealSituations: 'Alta confianza en situaciones reales',
    siteLanguage: 'Idioma del sitio',
    joinUsAndSpeakYourWay: 'Únete y habla para ser fluido con Language Rooms!',
  },
  errors: {
    error: 'Error',
    localStreamNotFound:
      'Flujo local no encontrado. Asegúrate de haber permitido que el navegador acceda a tu cámara y micrófono.',
  },
} as const;
