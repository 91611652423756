<script setup lang="ts">
import {
  Globe2,
  MessageCircle,
  Users,
  Brain,
  Clock,
  Zap,
  Rocket,
  Mail,
  Star,
  Lock,
  UserPlus,
  CheckCircle,
  MessageSquare,
  MessageSquareIcon,
  CheckCircleIcon,
  ArrowRightIcon,
  UserPlusIcon,
  RocketIcon,
  Mic,
  Laugh,
  BookOpen,
  ArrowRight,
} from 'lucide-vue-next';

import { computed, ref } from 'vue';
import { analytics, db, waitlistRef } from '../../firebase';
import { push, ref as dbRef, set, get } from 'firebase/database';
import { useTranslations } from '../composables/useTranslations';
import { logEvent } from 'firebase/analytics';

const { translations } = useTranslations();

const reasonsWhy = computed(() => [
  {
    icon: Mic,
    title: translations.value.headlines.speakEarlySpeakOften,
    description: translations.value.texts.noMoreYearsOfSilentStudy,
  },
  {
    icon: Users,
    title: translations.value.headlines.learnFromNativeSpeakers,
    description: translations.value.texts.textBooksCantTeachYou,
  },
  {
    icon: Laugh,
    title: translations.value.headlines.embraceTheFun,
    description: translations.value.texts.learningALanguageShouldBeFun,
  },
]);

const targetGroups = computed(() => [
  {
    icon: Zap,
    title: translations.value.headlines.progressingLearners,
    description: translations.value.texts.alreadySpeakingALittle,
  },
  {
    icon: Brain,
    title: translations.value.headlines.rustySpeakers,
    description: translations.value.texts.outOfPracticeQuestion,
  },
  {
    icon: Rocket,
    title: translations.value.headlines.skillMaintainers,
    description: translations.value.texts.dontWantToLoseYourSkills,
  },
  {
    icon: Users,
    title: translations.value.headlines.culturalEnthusiasts,
    description: translations.value.texts.diveDeeperIntoTheCultures,
  },
]);

const howSteps = computed(() => [
  {
    title: translations.value.headlines.joinARoom,
    description: translations.value.texts.pickAThemedRoom,
    image: 'images/pexels-tim-douglas-6205585.jpg',
  },
  {
    title: translations.value.headlines.startSpeaking,
    description: translations.value.texts.startSpeakingWithOthers,
    image: 'images/people-2567915_1280.jpg',
  },
  {
    title: translations.value.headlines.levelUpYourLingo,
    description: translations.value.texts.watchYourLanguageSkillsFlourish,
    image: 'images/pexels-wesleydavi-4126255 (1).jpg',
  },
]);

const roadmap = computed(() => [
  {
    icon: CheckCircleIcon,
    title: translations.value.headlines.phaseOneLaunch,
    description: translations.value.texts.coreFeaturesAndInitialFeatures,
  },
  {
    icon: UserPlusIcon,
    title: translations.value.headlines.phaseTwoCommunityGrowth,
    description: translations.value.texts.expandUserbase,
  },
  {
    icon: RocketIcon,
    title: translations.value.headlines.phaseThreeGlobalScale,
    description: translations.value.texts.worldwideAccessibility,
  },
]);

const feedbackReasons = computed(() => [
  translations.value.texts.shapeTheFutureOfLanguageRooms,
  translations.value.texts.suggestNewFeaturesAndImprovements,
  translations.value.texts.reportAnyIssues,
  translations.value.texts.shareYourSuccessStories,
]);

const traditionalMethodItems = computed(() => [
  translations.value.texts.extensiveGrammarFocus,
  translations.value.texts.vocabularyMemorization,
  translations.value.texts.limitedPracticalApplication,
]);

const languageRoomsMethodItems = computed(() => [
  translations.value.texts.focusOnPracticalUsage,
  translations.value.texts.dailyConversationPractice,
  translations.value.texts.highConfidenceInRealSituations,
]);

const earlyAccessEmail = ref('');
const earlyAccessRequested = ref(false);

const activePhase = ref(0);

const togglePhase = (index: number) => {
  activePhase.value = activePhase.value === index ? -1 : index;
};

async function submitEarlyAccess() {
  if (analytics) {
    logEvent(analytics, 'click_request_early_access_button', {
      event_category: 'early_access',
    });
  }

  // check if email is already in the waitlist
  const waitlistSnapshot = await get(dbRef(db, waitlistRef));
  const waitlistEmails = Object.values(waitlistSnapshot.val() ?? {});
  const emailExists = waitlistEmails.includes(earlyAccessEmail.value);

  if (emailExists) {
    earlyAccessEmail.value = 'You are already on the waitlist!';
    earlyAccessRequested.value = true;
    return;
  }

  try {
    // add new doc with firebase and automatic ID
    const newWaitlistRef = push(dbRef(db, waitlistRef));

    await set(newWaitlistRef, earlyAccessEmail.value);
  } catch (error: unknown) {
    console.error(error);
  }

  earlyAccessEmail.value = '';
  earlyAccessRequested.value = true;
}
</script>

<template>
  <div
    class="flex flex-col items-center min-h-screen bg-gradient-to-b from-primary-lightest to-secondary-lightest text-gray-800 space-y-24 py-12 md:space-y-48 md:py-24"
  >
    <!-- Hero Section -->
    <section class="px-4 sm:px-6">
      <div class="container mx-auto max-w-5xl">
        <div class="flex flex-col lg:flex-row items-center gap-16">
          <div class="lg:w-1/2 space-y-8">
            <h2 class="text-4xl font-bold leading-tight text-gray-800">
              {{ translations.headlines.speakLearnConnect }}
              <span
                class="bg-gradient-to-r from-primary to-secondary text-transparent bg-clip-text whitespace-nowrap"
                >Language Rooms.</span
              >
            </h2>
            <p class="text-xl text-gray-600">
              {{ translations.texts.connectWithNativeSpeakersEngage }}
            </p>
            <div class="flex flex-col sm:flex-row gap-4">
              <a
                href="#early-access"
                class="bg-gradient-to-r from-primary to-secondary hover:from-blue-600 hover:to-purple-600 text-white px-8 py-3 rounded-lg text-lg shadow-md cursor-pointer"
                @click="
                  if (analytics) {
                    logEvent(analytics, 'click_join_waitlist_hero_link', {
                      event_category: 'early_access',
                    });
                  }
                "
              >
                {{ translations.buttons.joinTheWaitlist }}
              </a>
              <button
                disabled
                class="border text-primary border-primary hover:bg-primary-lightest px-8 py-3 rounded-lg text-lg"
              >
                {{ translations.buttons.exploreRooms }}
              </button>
            </div>
          </div>
          <div class="lg:w-1/2">
            <div class="bg-white p-4 rounded-lg shadow-lg">
              <img
                :src="'images/hero9.png'"
                alt="Language exchange illustration"
                width="500"
                height="400"
                class="rounded-md"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="why-it-works" class="w-full space-y-8">
      <div class="space-y-8 px-4 sm:px-6">
        <h2 class="text-3xl font-bold text-center text-gray-800">
          {{ translations.headlines.whyLanguageRoomsWorks }}
        </h2>
        <p class="text-xl text-gray-600 mx-auto text-center max-w-xl">
          {{ translations.texts.ourPlatformFocusesOnSpeaking }}
          {{ translations.texts.joinUsAndSpeakYourWay }}
        </p>
      </div>
      <div class="flex flex-col md:flex-row">
        <div class="flex-1 bg-blue-100 p-12 flex items-center justify-center">
          <div class="bg-white shadow-xl rounded-lg max-w-md">
            <div class="p-8">
              <Clock class="w-16 h-16 text-blue-500 mb-6 mx-auto" />
              <h3 class="text-2xl font-semibold text-center text-gray-800 mb-4">
                {{ translations.headlines.traditionalMethod }}
              </h3>
              <div class="text-5xl font-bold text-center text-blue-500 mb-6">
                {{ translations.texts.sevenYears }}
              </div>
              <p class="text-gray-600 text-center mb-6">
                {{ translations.texts.yearsOfTextBooksAndGrammar }}
              </p>
              <ul class="space-y-3 text-gray-600">
                <li
                  v-for="(item, index) in traditionalMethodItems"
                  :key="index"
                  class="flex items-center"
                >
                  <BookOpen class="w-5 h-5 text-blue-500 mr-3 flex-shrink-0" />
                  <span>{{ item }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          class="flex-1 bg-gradient-to-br from-blue-400 to-purple-600 p-12 flex items-center justify-center"
        >
          <div class="bg-white shadow-xl rounded-lg max-w-md">
            <div class="p-8">
              <Zap class="w-16 h-16 text-purple-500 mb-6 mx-auto" />
              <h3 class="text-2xl font-semibold text-center text-gray-800 mb-4">
                {{ translations.headlines.languageRoomsMethod }}
              </h3>
              <div
                class="text-5xl font-bold text-center bg-gradient-to-r from-blue-400 to-purple-600 text-transparent bg-clip-text mb-6"
              >
                {{ translations.texts.threeMonths }}
              </div>
              <p class="text-gray-600 text-center mb-6">
                {{ translations.texts.immersiveLearningAndRealConversations }}
              </p>
              <ul class="space-y-3 text-gray-600">
                <li
                  v-for="(item, index) in languageRoomsMethodItems"
                  :key="index"
                  class="flex items-center"
                >
                  <ArrowRight
                    class="w-5 h-5 text-purple-500 mr-3 flex-shrink-0"
                  />
                  <span>{{ item }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Features Section -->
    <section class="px-4 sm:px-6">
      <div class="container mx-auto max-w-6xl space-y-14">
        <div id="method" class="space-y-8">
          <h3 class="text-2xl font-bold text-center">
            {{ translations.headlines.theLanguageRoomsMethod }}
          </h3>
          <h4 class="text-xl text-gray-600 mx-auto text-center max-w-4xl">
            {{ translations.texts.weHaveThreeCorePrinciples }}
          </h4>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div
              v-for="(reason, index) in reasonsWhy"
              :key="index"
              class="bg-white rounded-xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-2xl hover:-translate-y-1"
            >
              <div
                class="h-2 bg-gradient-to-r from-blue-500 to-purple-500"
              ></div>
              <div class="p-6">
                <component
                  :is="reason.icon"
                  class="w-12 h-12 text-blue-500 mb-4"
                />
                <h3 class="text-xl font-semibold mb-2 text-gray-800">
                  {{ reason.title }}
                </h3>
                <p class="text-gray-600">{{ reason.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="how-it-works" class="px-4 sm:px-6">
      <div class="container mx-auto max-w-5xl">
        <h2 class="text-3xl font-bold text-center mb-16 text-gray-800">
          {{ translations.headlines.howLanguageRoomsWorks }}
        </h2>
        <div class="space-y-16">
          <div
            v-for="(step, index) in howSteps"
            :key="index"
            :class="[
              'flex flex-col',
              index % 2 === 0 ? 'lg:flex-row' : 'lg:flex-row-reverse',
              'items-center gap-12',
            ]"
          >
            <div class="lg:w-1/2 space-y-4">
              <h3 class="text-2xl font-semibold text-gray-800">
                <span
                  class="size-10 bg-gradient-to-br from-blue-500 to-purple-500 rounded-full inline-flex items-center justify-center text-white font-bold mr-1"
                  >{{ index + 1 }}</span
                >
                {{ step.title }}
              </h3>
              <p class="text-lg text-gray-600">{{ step.description }}</p>
            </div>
            <div class="lg:w-1/2">
              <div class="bg-white p-4 rounded-lg shadow-lg">
                <img
                  :src="step.image"
                  :alt="`Step ${index + 1}: ${step.title}`"
                  class="rounded-md h-[300px] w-[400px] md:h-[400px] md:w-[500px] object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="target-group" class="px-4 sm:px-6">
      <div class="container mx-auto max-w-6xl">
        <div class="grid gap-12 lg:grid-cols-2">
          <div class="flex flex-col justify-center">
            <h2 class="text-3xl font-bold text-gray-800 mb-4">
              {{
                translations.headlines.perfectForIntermediateLearnersAndBeyond
              }}
            </h2>
            <p class="text-xl text-gray-600">
              {{ translations.texts.languageRoomsIsDesignedTo }}
            </p>
          </div>
          <div
            class="bg-white rounded-xl shadow-lg p-1 bg-gradient-to-br from-blue-500 to-purple-500"
          >
            <div class="bg-white rounded-lg p-2 sm:p-6">
              <h3 class="text-2xl font-semibold mb-6 text-center text-gray-800">
                {{ translations.headlines.whoBenefitsMost }}?
              </h3>
              <div class="space-y-6">
                <div
                  v-for="(group, index) in targetGroups"
                  :key="index"
                  class="flex items-start"
                >
                  <div
                    :class="`p-2 rounded-full mr-4 ${index % 2 === 0 ? 'bg-blue-100' : 'bg-purple-100'}`"
                  >
                    <component
                      :is="group.icon"
                      :class="`h-6 w-6 ${index % 2 === 0 ? 'text-blue-500' : 'text-purple-500'}`"
                    />
                  </div>
                  <div>
                    <strong
                      class="block text-lg font-semibold mb-1 text-gray-800"
                      >{{ group.title }}</strong
                    >
                    <p class="text-gray-600">{{ group.description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      id="early-access"
      class="py-16 md:py-24 px-4 sm:px-6 bg-gradient-to-r from-blue-500 to-purple-500 text-white w-full"
    >
      <div class="container mx-auto max-w-5xl">
        <div class="flex flex-col items-center space-y-8 text-center">
          <h2 class="text-3xl font-bold">
            {{ translations.headlines.becomeALanguageRoomsPioneer }}
          </h2>
          <p class="max-w-[600px] text-xl">
            {{ translations.texts.wereGraduatelyOpeningOurDoors }}
          </p>
          <form
            class="w-full max-w-md space-y-4"
            @submit.prevent="submitEarlyAccess"
          >
            <input
              v-model="earlyAccessEmail"
              :disabled="earlyAccessRequested"
              :placeholder="
                earlyAccessRequested
                  ? translations.texts.weAreExcitedToWelcomeYouSoon
                  : translations.texts.enterYourEmail
              "
              type="email"
              required
              class="w-full px-3 py-2 bg-white text-gray-800 rounded-md"
            />
            <button
              type="submit"
              :disabled="earlyAccessRequested"
              class="w-full bg-white text-blue-500 hover:bg-blue-50 px-8 py-3 rounded-lg text-lg"
            >
              {{
                earlyAccessRequested
                  ? translations.buttons.earlyAccessRequested
                  : translations.buttons.requestEarlyAccess
              }}
            </button>
          </form>
          <p class="text-sm mt-4">
            {{ translations.texts.onceYourEmailIsApproved }}
          </p>
        </div>
      </div>
    </section>

    <section id="roadmap" class="px-4 sm:px-6">
      <div class="container mx-auto max-w-6xl">
        <h2 class="text-3xl font-bold text-center mb-16 text-gray-800">
          {{ translations.headlines.ourExcitingRoadmap }}
        </h2>
        <div class="hidden sm:block relative">
          <div
            class="absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-gradient-to-b from-blue-500 to-purple-500"
          ></div>
          <div
            v-for="(phase, index) in roadmap"
            :key="index"
            :class="[
              'flex items-center mb-12',
              index % 2 === 0 ? 'flex-row' : 'flex-row-reverse',
            ]"
          >
            <div
              :class="[
                'w-1/2',
                index % 2 === 0 ? 'pr-12 text-right' : 'pl-12 text-left',
              ]"
            >
              <div class="bg-white p-6 rounded-xl shadow-lg">
                <component
                  :is="phase.icon"
                  class="w-12 h-12 text-purple-500 mb-4 mx-auto"
                />
                <h3 class="text-xl font-semibold mb-2 text-gray-800">
                  {{ phase.title }}
                </h3>
                <p class="text-gray-600">{{ phase.description }}</p>
              </div>
            </div>
            <div
              class="w-10 h-10 bg-gradient-to-br from-blue-500 to-purple-500 rounded-full flex items-center justify-center text-white font-bold z-10"
            >
              {{ index + 1 }}
            </div>
            <div class="w-1/2"></div>
          </div>
        </div>
        <div class="block sm:hidden space-y-4">
          <div
            v-for="(phase, index) in roadmap"
            :key="index"
            class="bg-white rounded-xl shadow-lg overflow-hidden"
          >
            <button
              class="w-full p-6 text-left focus:outline-none"
              :class="{
                'bg-gradient-to-r from-blue-500 to-purple-500 text-white':
                  activePhase === index,
              }"
              @click="togglePhase(index)"
            >
              <div class="flex items-center justify-between">
                <h3 class="text-xl font-semibold">{{ phase.title }}</h3>
                <component
                  :is="activePhase === index ? 'ChevronUp' : 'ChevronDown'"
                  class="w-6 h-6"
                />
              </div>
            </button>
            <div v-show="activePhase === index" class="p-6 border-t">
              <component
                :is="phase.icon"
                class="w-12 h-12 text-purple-500 mb-4"
              />
              <p class="text-gray-600">{{ phase.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="feedback" class="px-4 sm:px-6">
      <div class="container mx-auto max-w-4xl">
        <div class="bg-white rounded-2xl shadow-2xl overflow-hidden">
          <div class="h-2 bg-gradient-to-r from-blue-500 to-purple-500"></div>
          <div class="p-4 md:p-12">
            <MessageSquareIcon class="w-20 h-20 text-purple-500 mb-8 mx-auto" />
            <h2 class="text-3xl font-bold text-center mb-8 text-gray-800">
              {{ translations.headlines.yourVoiceMatters }}
            </h2>
            <p class="text-xl text-center text-gray-600 mb-12">
              {{ translations.texts.weValueYourInput }}
            </p>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div class="bg-gray-50 p-6 rounded-xl">
                <h3 class="text-xl font-semibold text-gray-800 mb-4">
                  {{ translations.headlines.whyYourFeedBackIsCrucial }}:
                </h3>
                <ul class="text-gray-600 space-y-2">
                  <li
                    v-for="(item, index) in feedbackReasons"
                    :key="index"
                    class="flex items-center"
                  >
                    <ArrowRightIcon class="w-5 h-5 text-blue-500 mr-2" />
                    {{ item }}
                  </li>
                </ul>
              </div>
              <div class="bg-gray-50 p-6 rounded-xl">
                <h3 class="text-xl font-semibold text-gray-800 mb-4">
                  {{ translations.headlines.howToProvideFeedback }}:
                </h3>
                <p class="text-gray-600 mb-4">
                  {{ translations.texts.lookForTheFeedbackButton }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.journey-step {
  animation: fadeInUp 0.5s ease-out both;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
