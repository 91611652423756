import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import Home from './pages/Home.vue';
// import Rooms from './pages/rooms/Rooms.vue';
// import New from './pages/rooms/New.vue';
// import Room from './pages/rooms/Room.vue';
// import Login from "./pages/Login.vue";
// import Signup from "./pages/Signup.vue";
// import New from "./pages/rooms/New.vue";
// import About from "./pages/About.vue";
// import Rooms from "./pages/rooms/Rooms.vue";
// import Room from "./pages/rooms/Room.vue";
// import MVP from "./pages/MVP.vue";

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: Home,
    name: 'home',
  },
  // {
  //   path: '/rooms',
  //   component: Rooms,
  //   name: 'rooms',
  // },
  // {
  //   path: '/rooms/:roomId',
  //   component: Room,
  //   name: 'room',
  // },
  // {
  //   path: '/rooms/new',
  //   component: New,
  //   name: 'new',
  // },

  // { path: '/about', component: About },
  // { path: '/login', component: Login },
  // { path: '/signup', component: Signup },
  // { path: '/mvp', component: MVP },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});
